import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { RequestInterceptorService } from './request-interciptor.service';
import { switchMap } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  isConnected = false;
  private authenticated = false;

  constructor(
    private requestInterceptorService: RequestInterceptorService,
    private apiService: ApiService,
    private route: Router
  ) { }

  /**
   * Retrieves the CSRF token from the server.
   *
   * @return {Observable<any>} An observable that emits the CSRF token.
  */
  getCSRFToken(): Observable<any> {
    return this.apiService.httpGet(environment.ENDPOINTS.CSRF_URL);
  }

  /**
   * Logs the user in by sending a POST request to the login endpoint.
   *
   * @param {object} credentiels - The credentials of the user.
   * @param {any} resp - The response type.
   * @return {Observable<any>} An observable that emits the response from the server.
  */
  login(credentiels, resp: any): Observable<any> {
    return this.getCSRFToken().pipe(
      switchMap((csrfToken: string) => {
        const options = {
          headers: { 'X-XSRF-TOKEN': csrfToken }
        };
        return this.apiService.httpPost(environment.ENDPOINTS.LOGIN_URL + `?response=${resp}`, credentiels, options);
      })
    );
  }


  /**
   * Activates the user account by sending a POST request to the activate account endpoint.
   *
   * @param {any} params - The parameters needed to activate the account.
   * @return {Observable<any>} An observable that emits the response from the server.
  */
  activateAccount({ pass, isEmployeePortalActivation }: { pass: any, isEmployeePortalActivation: boolean }): Observable<any> {
    const isEmployeePortalActivationParams = isEmployeePortalActivation !== null ? `isEmployeePortalActivation=${isEmployeePortalActivation}` : '';
    const urlWithParams = `${environment.ENDPOINTS.ACTIVATE_ACCOUNT_URL}?${isEmployeePortalActivationParams}`;
    return this.apiService.httpPost(urlWithParams, pass);
  }


  setAuthenticated(auth: boolean) {
    this.authenticated = auth;
  }

  /**
   * Retrieves the reset password link by sending a POST request to the forgot password URL.
   *
   * @param {any} email - The email address to use for the reset password link.
   * @return {Observable<any>} An observable that emits the response from the server.
  */
  getResetPasswordLink(email): Observable<any> {
    return this.apiService.httpPost(environment.ENDPOINTS.FORGOT_PASSWORD_URL, email);
  }

  /**
   * Retrieves the reset password link by sending a POST request to the reset password URL.
   *
   * @param {any} params - The parameters needed for the reset password request.
   * @return {Observable<any>} An observable that emits the response from the server.
  */
  resetPassword(params): Observable<any> {
    return this.apiService.httpPost(environment.ENDPOINTS.RESET_PASSWORD_URL, params);
  }

  /**
   * A function that changes the password.
   *
   * @param {any} values - The new password value.
   * @return {Observable<any>} An observable that emits the response from the server.
  */
  changePassword(values): Observable<any> {
    return this.apiService.httpPost(environment.ENDPOINTS.CHANGE_PASSWORD_URL, values);
  }


  /**
   * Logs the user out by sending a POST request to the logout endpoint.
   *
   * @return {Observable<any>} An observable that emits the response from the server.
  */
  logout(): Observable<any> {
    return this.apiService.httpPost(environment.ENDPOINTS.LOGOUT_URL, '');
  }

  /**
   * Generates query parameters from a map of properties.
   *
   * @param {Map<string, any>} propertiesList - The map of properties to convert to query parameters.
   * @return {HttpParams} The generated HttpParams object.
  */
  queryParamsGenerator({ propertiesList }: { propertiesList: Map<string, any> }): HttpParams {
    let httpParams = new HttpParams();
    propertiesList?.forEach((value, key) => {
      if (value != null && value !== '') {
        httpParams = httpParams.append(key, `${value}`);
      }
    });
    return httpParams;
  }


}